import { rhythm } from "./typography"
import {
  lineHeights,
  fontSizes,
  mediaQueries,
  colors,
  fonts,
  transition,
  letterSpacings,
  shadows,
  space,
} from "./presets"
import { hexToRGBA } from "gatsby-interface"
import { LayoutWidth } from "../components/shared/constants/layout"

const titleBaseStyles = {
  WebkitFontSmoothing: `antialiased`,
  textRendering: `optimizelegibility`,
  fontFamily: fonts.heading,
  letterSpacing: letterSpacings.tight,
  lineHeight: 1.125,
}

const titleStyles = {
  ...titleBaseStyles,
  color: colors.grey[90],
  fontWeight: 800,
  transition: `color ${transition.speed.default} ${transition.curve.default}`,
  [mediaQueries.tablet]: {
    fontSize: fontSizes[9],
  },
  [mediaQueries.hd]: {
    fontSize: fontSizes[10],
  },
  "& span": {
    fontWeight: `normal`,
  },
}

const subtitleStyles = {
  ...titleBaseStyles,
  fontSize: fontSizes[5],
  fontWeight: `normal`,
  [mediaQueries.tablet]: {
    fontSize: fontSizes[6],
  },
}

// gatsby-remark-autolink-headers
const anchorStyles = {
  "a.anchor": {
    color: `inherit`,
    fill: colors.lilac,
    textDecoration: `none`,
    borderBottom: `0`,
  },
}

const articleStyles = {
  // Target image captions. This is kind of a fragile selector...
  "img + em": {
    fontSize: fontSizes[1],
    lineHeight: lineHeights.dense,
    marginTop: `-${space[7]}`,
    marginBottom: space[7],
    display: `block`,
    textAlign: `center`,
    color: colors.gray.calm,
  },
  "img, .gatsby-resp-image-link": {
    boxShadow: shadows.dialog,
    marginTop: space[7],
    marginBottom: space[7],
  },
  "p, ol, ul, h1, h2, h3, h4, h5, h6, .gatsby-resp-iframe-wrapper": {
    maxWidth: rhythm(26),
  },
  h2: {
    marginTop: space[10],
  },
  "h3, h4": {
    marginTop: space[7],
  },
  table: {
    fontSize: fontSizes[1],
    marginTop: space[10],
    marginBottom: space[10],
  },
  ...anchorStyles,
}

const bodyCopy = {
  color: colors.gray.neutral,
  // lineHeight: lineHeights.loose,
  [mediaQueries.tablet]: {
    fontSize: fontSizes[3],
    maxWidth: rhythm(24),
  },
}

const defaultPaddingHorizontal = {
  paddingLeft: space[8],
  paddingRight: space[8],
  [mediaQueries.tablet]: {
    paddingLeft: space[8],
    paddingRight: space[8],
  },
}

const howItWorksSVGStyles = theme => ({
  "& svg path": {
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-stroke": {
    stroke: theme.colors.blue[90],
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-fill": {
    fill: theme.colors.blue[90],
  },
  "& .icon-apps-gradient-1": {
    stroke: `url(#icon-apps-gradient-1-blue)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-2": {
    stroke: `url(#icon-apps-gradient-2-blue)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-3": {
    fill: `url(#icon-apps-gradient-3-blue)`,
  },
  "& .icon-performance-gradient-1": {
    fill: `url(#icon-performance-gradient-1-blue)`,
  },
})

const smallCapsStyles = {
  color: colors.gray.neutral,
  fontSize: fontSizes[1],
  fontFamily: fonts.heading,
  letterSpacing: letterSpacings.tracked,
  textTransform: `uppercase`,
}

const howItWorksSVGHoverStyles = theme => ({
  "& svg path": {
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-stroke": {
    stroke: theme.colors.gatsby,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-fill": {
    fill: theme.colors.gatsby,
  },
  "& .icon-apps-gradient-1": {
    stroke: `url(#icon-apps-gradient-1)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-2": {
    stroke: `url(#icon-apps-gradient-2)`,
    vectorEffect: `non-scaling-stroke`,
  },
  "& .icon-apps-gradient-3": {
    fill: `url(#icon-apps-gradient-3)`,
  },
  "& .icon-performance-gradient-1": {
    fill: `url(#icon-performance-gradient-1)`,
  },
})

const linkStyles = {
  position: `relative`,
  fontWeight: 500,
  textDecoration: `none`,
  borderBottom: `1px solid ${colors.lilac}`,
  transition: `all ${transition.speed.default} ${transition.curve.default}`,
  "&:hover": {
    borderBottomColor: `transparent`,
  },
}

const guideContentBlockStyles = {
  marginTop: space[13],
  marginBottom: space[13],
  [mediaQueries.phablet]: {
    "& ul, & ol": {
      marginLeft: 0,
    },
  },
  "& a": {
    color: colors.gatsby,
    fontWeight: 400,
    ...linkStyles,
  },
}

const guideContainer = {
  marginLeft: `auto`,
  marginRight: `auto`,
  [mediaQueries.phablet]: {
    width: `90%`,
    maxWidth: rhythm(26),
  },
  [mediaQueries.desktop]: {
    maxWidth: rhythm(28),
  },
  ...defaultPaddingHorizontal,
}

const defaultPadding = {
  paddingBottom: space[7],
  paddingLeft: rhythm(8),
}

const guidePaddingBottom = {
  paddingBottom: space[10],
  [mediaQueries.phablet]: {
    paddingBottom: space[15],
  },
}

const gridBackground = theme => ({
  background: hexToRGBA(theme.colors.blue[10], 0.5, true),
})

const withHowItWorksGridBorder = theme => ({
  position: `relative`,
  "&:after": [
    gridBackground(theme),
    {
      content: `" "`,
      display: `block`,
      height: 1,
      left: `-100vw`,
      right: `-100vw`,
      bottom: 0,
      position: `absolute`,
    },
  ],
})

const primaryButtonHoverStyle = {
  background: colors.gatsby,
}

const primaryButtonDefaultStyles = {
  background: colors.gatsby,
  ":hover": {
    ...primaryButtonHoverStyle,
  },
  ":focus": {
    outline: 0,
  },
}

const container = {
  position: `relative`,
  zIndex: 1,
  margin: `0 auto`,
  width: `100%`,
  maxWidth: `${LayoutWidth}rem`,
  ...defaultPaddingHorizontal,
  [mediaQueries.phablet]: {
    width: `90%`,
  },
}

const visuallyHidden = {
  // include `px` so we can use it with `sx`
  border: 0,
  clip: `rect(0, 0, 0, 0)`,
  height: `1px`,
  margin: `-1px`,
  overflow: `hidden`,
  padding: 0,
  position: `absolute`,
  whiteSpace: `nowrap`,
  width: `1px`,
}

export {
  linkStyles,
  howItWorksSVGStyles,
  howItWorksSVGHoverStyles,
  titleStyles,
  subtitleStyles,
  guideContentBlockStyles,
  guideContainer,
  guidePaddingBottom,
  defaultPadding,
  defaultPaddingHorizontal,
  withHowItWorksGridBorder,
  bodyCopy,
  primaryButtonDefaultStyles,
  primaryButtonHoverStyle,
  gridBackground,
  anchorStyles,
  articleStyles,
  smallCapsStyles,
  container,
  visuallyHidden,
}
